@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: whitesmoke;
}

::-webkit-scrollbar
{
	width: 4px;
	background-color: whitesmoke;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

body {
  background: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(64, 20, 103) 100%);
  animation: gradient 10s ease infinite;
  background-size: 200%;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  margin-block: 5dvh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1 {
  font-size: 4em;
  font-weight: 600;
  /* font-style: italic; */
  font-family: 'Rubik', sans-serif;
  padding: 0 4vmin !important;
}

h2 {
  font-size: 2.5em;
  font-weight: 500 !important;
  font-family: 'Rubik', sans-serif;
}

h1, h2, h3 {
  padding: 2vmin 0;
  margin: 0 !important;
}

a img {
  border: 1px solid rgba(0, 0, 0, 0.59);
  /* opacity: 65%; */
  /* transition: all 1s ease-in-out; */
}

/* a img:hover {
  opacity: 100%;
} */

p {
  font-weight: 400;
  font-size: 1.1em;
  padding-block: 0;
  margin: 0 !important;
}

ul {
  font-size: larger;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

a {
  color: black !important;
  font-weight: 600;
}

.col {
  padding: 0 !important;
}

.card, .card-action {
  border-radius: 7px;
  margin: 5px;
  height: clamp(100px, 45%, 500px);
  overflow: hidden;
}

.card {
  scale: 1;
  transition: all .2s ease-in-out;
  padding: 5px 5px !important;
}

/* .medium:hover {
  scale: 1.5;
  z-index: 10;
  transition: all .2s ease-in-out;
  box-shadow: 30px 30px 300px 300px rgba(0, 0, 0, 0.8);
} */

.demoPic {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center top;
  border-radius: 7px !important;
}

/* .project {
  padding: 10px;
} */

.tabs {
  overflow-y: scroll;
}

.tabs .tab a:hover {
  filter: blur(.5rem);
  transition: 5s ease;
}

.tabs::-webkit-scrollbar {
  width: 2px;
}

#portrait {
  margin: 15px;
  border-radius: 15px;
  object-fit: fill;
  justify-self: center;
  aspect-ratio: initial;
  min-width: 150px;
  box-shadow: 2px 2px 14px 1px rgba(0, 0, 0, 0.8);
}

@media ( width <= 600px) {
  .main {
    width: 100vw;
  }
  .demoPic {
    width: 100px;
    align-self: center;
  }
  .horizontal {
    flex-direction: column;
    align-items: center;
  }
  .medium:hover {
    scale: 1.1;
    z-index: 10;
    transition: all .2s ease-in-out;
    box-shadow: 30px 30px 300px 300px rgba(0, 0, 0, 0.8);
  }
  #portrait::before {
    box-shadow: 5px 5px 14px 1px rgba(0, 0, 0, 0.8);
  }

  #portrait {
    margin: 0;
    /* clip-path: circle(30% at 50% 40%); */
  }
}